.flipCard {
  perspective: 1000px;
  width: 300px;
  height: 400px;
  margin: 20px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0; /* Remove padding from the card */
  border-radius: 6px;
}

.flipCardBack p {
  padding: 10px;
  color: #636b72;
  font-family: "opensans-semibold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word;
}

.flipCardFront p {
  color: #636b72;
  font-family: "opensans-semibold", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.flipCardFront {
  background: linear-gradient(135deg, #83a4d4, #b6fbff);
  color: black;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.flipCardBack {
  background: linear-gradient(135deg, #b6fbff, #83a4d4);
  color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: rotateY(180deg);
}

.cardImage {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-top-left-radius: 6px; /* Match the border-radius of the card */
  border-top-right-radius: 6px; /* Match the border-radius of the card */
}
